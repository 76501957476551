import axios from 'axios';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import {
  Depot,
  Settings,
  DepotState,
  Documents, ExemptionOrder,
  IncomeStatements,
  Payouts,
  Revenues,
  Saldo,
  SecurityEvents, SecuritySettlements,
  Stocks, TaxCertificates,
  Transactions, TaxEqualizations,
  ContenfullProjectUpdates,
} from './types';
import { RootState } from '@/store/types';

const state: DepotState = {
  user_depot: undefined,
  loadingDepot: false,
  settings: undefined,
  stocks: undefined,
  saldo: undefined,
  exemptionOrder: undefined,
  payouts: undefined,
  transactions: undefined,
  revenues: undefined,
  loadingDocuments: false,
  documents: undefined,
  blocked: false,
};

const mutations: MutationTree<DepotState> = {
  SET_LOADING_DEPOT(state: DepotState, loadingDepot: Boolean) {
    state.loadingDepot = loadingDepot;
  },
  SET_DEPOT(state: DepotState, depot: Depot) {
    state.user_depot = depot;
  },
  SET_SETTINGS(state: DepotState, settings: Settings) {
    state.settings = settings;
  },
  SET_STOCKS(state: DepotState, stocks: Stocks) {
    state.stocks = stocks;
  },
  SET_TRANSACTIONS(state: DepotState, transactions: Transactions) {
    state.transactions = transactions;
  },
  SET_REVENUES(state: DepotState, revenues: Revenues) {
    state.revenues = revenues;
  },
  SET_SALDO(state: DepotState, saldo: Saldo) {
    state.saldo = saldo;
  },
  SET_EXEMPTION_ORDER(state: DepotState, exemptionOrder: ExemptionOrder) {
    state.exemptionOrder = exemptionOrder;
  },
  SET_PAYOUTS(state: DepotState, payouts: Payouts) {
    state.payouts = payouts;
  },
  SET_LOADING_DOCUMENTS(state: DepotState, loadingDocuments: Boolean) {
    state.loadingDocuments = loadingDocuments;
  },
  SET_DOCUMENTS(state: DepotState, documents: Documents) {
    state.documents = documents;
  },
  SET_BLOCKED(state: DepotState, blocked: boolean) {
    state.blocked = blocked;
  },
};

const getters: GetterTree<DepotState, RootState> = {
  loadingDepot: (state: DepotState) => state.loadingDepot,
  depot: (state: DepotState) => state.user_depot,
  settings: (state: DepotState) => state.settings,
  stocks: (state: DepotState) => state.stocks,
  payouts: (state: DepotState) => state.payouts,
  saldo: (state: DepotState) => state.saldo,
  exemptionOrder: (state: DepotState) => state.exemptionOrder,
  transactions: (state: DepotState) => state.transactions,
  revenues: (state: DepotState) => state.revenues,
  loadingDocuments: (state: DepotState) => state.loadingDocuments,
  documents: (state: DepotState) => state.documents,
  blocked: (state: DepotState) => state.blocked,
  getStockById: (state: DepotState) => (stockId: string) => {
    return !!stockId && state.stocks
      ? state.stocks?.data.find(stock => stock.wpNr === stockId)
      : null;
  },
};

const actions: ActionTree<DepotState, RootState> = {

  fetchUserDepotData({ commit, rootState, dispatch }: { commit: Function, rootState: any, dispatch: Function }) {
    if (!rootState.profile.baaderUser) {
      return null;
    }

    commit('SET_LOADING_DEPOT', true);

    return axios.get(`${process.env.VUE_APP_BAADER_DEPOT_API_URL}/cockpit?user=` + rootState.profile.baaderUser.user_id)
      .then(response => {

        const transactions: Transactions = {
          data: response.data.transactions,
        };
        const stocks: Stocks = {
          data: response.data.stocks,
        };
        const revenues: Revenues = {
          data: response.data.revenues,
        };
        const payouts: Payouts = {
          data: response.data.payouts,
        };

        commit('SET_DEPOT', response.data.depot);
        commit('SET_SETTINGS', response.data.settings);
        commit('SET_STOCKS', stocks);
        commit('SET_TRANSACTIONS', transactions);
        commit('SET_REVENUES', revenues);
        commit('SET_PAYOUTS', payouts);
        commit('SET_SALDO', response.data.saldo);
        commit('SET_EXEMPTION_ORDER', response.data.exemptionOrder);
        commit('SET_BLOCKED', response.data.blocked);

        return Promise.resolve(response.data);
      })
      .catch(error => {
        commit('SET_DEPOT', undefined);

        return Promise.reject(error.response);
      })
      .finally(() => commit('SET_LOADING_DEPOT', false));
  },

  fetchDocuments({ commit, rootState }: { commit: Function, rootState: any, dispatch: Function }) {
    if (!rootState.profile.baaderUser) {
      return null;
    }

    const filteredFPIds = [
      '3qoxe9kpraezfb',
      '3qoxe9kpraelv0',
      '3qoxe9kprae7dq',
      '3qoxe9kprae0vh',
      '3qoxe9kpradz6x',
      '3qoxe9kpraduo4',
    ];

    commit('SET_LOADING_DOCUMENTS', true);
    commit('SET_DOCUMENTS', undefined);

    const client = axios.create({
      baseURL: 'https://cdn.contentful.com',
      timeout: 10000,
      //TODO make dynamic token
      headers: { 'Authorization': 'Bearer ' + process.env.VUE_APP_CONTENTFUL_API_KEY },
    });
    // This API call will request an entry with the specified ID from the space defined at the top, using a space-specific access token

    return Promise.all([
      //Call to Baader Bank service API
      axios.get(`${process.env.VUE_APP_BAADER_DEPOT_API_URL}/document?user=` + rootState.profile.baaderUser.user_id),

      //Call to Contentfull API to get Baader Bank Project updates
      client.get('/spaces/4xa6vepaoovq/environments/master/entries?content_type=project&cursor=true&skip=0&limit=100&order=-sys.updatedAt&include=2&select=fields.project_title,fields.financialProductId,fields.project_updates,sys'),
      client.get('/spaces/4xa6vepaoovq/environments/master/entries?content_type=project&cursor=true&skip=100&limit=200&order=-sys.updatedAt&include=2&select=fields.project_title,fields.financialProductId,fields.project_updates,sys'),
      client.get('/spaces/4xa6vepaoovq/environments/master/entries?content_type=project&cursor=true&skip=200&limit=300&order=-sys.updatedAt&include=2&select=fields.project_title,fields.financialProductId,fields.project_updates,sys'),
      client.get('/spaces/4xa6vepaoovq/environments/master/entries?content_type=project&cursor=true&skip=300&limit=400&order=-sys.updatedAt&include=2&select=fields.project_title,fields.financialProductId,fields.project_updates,sys'),
    ])
      .then(response => {

        const securityEventsOld: SecurityEvents = {
          data: response[0].data['wertpapier-ereignisse'],
        };

        const securityEvents: SecurityEvents = {
          data: response[0].data['wertpapier-ereignis-swift-faehig'],
        };

        const incomingStates: IncomeStatements = {
          data: response[0].data['ertraegnisaufstellung-neu'],
        };

        const taxCertificates: TaxCertificates = {
          data: response[0].data['jstb-1'],
        };

        const taxEqualization: TaxEqualizations = {
          data: response[0].data['topfausgleich'],
        };

        const securitySettlements: SecuritySettlements = {
          data: response[0].data.wertpapierabrechnung,
        };

        const projects = [...response[1].data.items, ...response[2].data.items, ...response[3].data.items, ...response[4].data.items];
        const projectUpdates = [];
        projects
          .filter(item => !item.sys.archivedAt && filteredFPIds.includes(item.fields.financialProductId))
          .map(item => {
            const items = item.fields.project_updates
            .map((update => {
              return { ...update, projectName: item.fields.project_title };
            }));

            projectUpdates.push(...items);

            return item;
          }
          );

        let projectUpdatesData = [];
        Promise.all(projectUpdates.map(item => client.get('/spaces/4xa6vepaoovq/environments/master/entries/' + item.sys.id).then(update => {

          const newDate = new Date(update.data.fields.date);
          newDate.setDate(new Date(update.data.fields.date).getDate() + 1);

          return {
            date: newDate.toISOString().split('T')[0],
            id: update.data.sys.id,
            name: update.data.fields.title + ' - ' + item.projectName,
            url: null,
            content: update.data.fields.content,
          };

        }).catch(err => false)
        )).then(items => {
          projectUpdatesData.push(...items.filter(item => !!item ? item : null));
          projectUpdatesData = projectUpdatesData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        });

        const contentfullProjectUpdates: ContenfullProjectUpdates = {
          data: projectUpdatesData,
        };

        const documents: Documents = {
          securityEventsOld: securityEventsOld,
          securityEvents: securityEvents,
          taxEqualization: taxEqualization,
          incomeStatements: incomingStates,
          taxCertificates: taxCertificates,
          securitySettlements: securitySettlements,
          contentfullProjectUpdates: contentfullProjectUpdates,
        };

        commit('SET_DOCUMENTS', documents);

        return Promise.resolve(documents);
      })
      .catch(error => {
        commit('SET_DOCUMENTS', undefined);

        return Promise.reject(error.response);
      })
      .finally(() => commit('SET_LOADING_DOCUMENTS', false));
  },

  createPayOut({ dispatch }: { dispatch: Function }, { amount }: { amount: string }) {
    const payload = {
      amount: amount,
    };

    return axios.post(`${process.env.VUE_APP_BAADER_DEPOT_API_URL}/payout`, payload)
      .finally(() => {
        dispatch('fetchUserDepotData', {});
      });
  },

  updateSetting({ dispatch }: { dispatch: Function }, { setting, value }: { setting: string, value: boolean }) {
    const payload = {
      setting: setting,
      value: value,
    };

    return axios.post(`${process.env.VUE_APP_BAADER_DEPOT_API_URL}/update-account-setting`, payload)
      .finally(() => {
        dispatch('fetchUserDepotData', {});
      });
  },
};

export const depot: Module<DepotState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
